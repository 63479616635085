import axios from 'axios'
import { APIPath } from 'baserURL'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { VscError } from "react-icons/vsc";
import { BiSolidError } from 'react-icons/bi';
import DivLoader from 'components/divloader/DivLoader';

function PaymentRedirectPage() {
    const [isLoading, setLoading] = useState(true)
    const [paymentUrl, setpaymentUrl] = useState("")
    const { gatewayid, internalid } = useParams()
    const navigate = useNavigate()
    const fetchPaymentURI = async () => {
        try {
            const apiurl = APIPath + `/api/v1/payment/redirect-uri/${gatewayid}/${internalid}`
            const res = await axios.get(apiurl)
            console.log("res", res)
            setpaymentUrl(res?.data?.redirect_uri)
            if (res?.data?.redirect_uri) {
                window.location.replace(res?.data?.redirect_uri)
            }

        } catch (error) {
            console.log("error", error)

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchPaymentURI()
    }, [])
    const Loader = () => {
        if (isLoading) {
            return <div className='mt-5 pt-5'>
                <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                <p className='text-center'>Loading please wait...</p>

            </div>
        }
    }

    return (
        <div>

            {isLoading ? Loader() : paymentUrl ? <iframe style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} src={`${paymentUrl}`} title="W3Schools Free Online Web Tutorials">
            </iframe> :
                <div className='h-screen flex flex-col justify-center'>
                    <div className='self-center '>
                        <BiSolidError className="mx-auto text-6xl text-red-500" />
                    </div>
                    <h5 className='self-center text-black-500 opacity-50'>Something went wrong, please check the url.</h5>
                </div>
            }
        </div>
    )
}

export default PaymentRedirectPage
